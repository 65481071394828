import { Pipe, PipeTransform } from '@angular/core';
import { UserTimezoneService } from '../services/user-timezone.service';

@Pipe({
  name: 'parseDate'
})
export class ParseDatePipe implements PipeTransform {
  constructor(private userTimezoneService: UserTimezoneService) {
  }

  transform(value: string): Date | null {
    const zonedDate = this.userTimezoneService.toUserTimezone(value);
    return zonedDate;
  }
}
