export const environment = {
  developmentMode: true,
  cognito: {
    userPoolId: 'ap-southeast-1_m5dzTkIz1',
    userPoolClientId: '7et7k26dsdskjh6v5safm87es5',
    region: 'ap-southeast-1'
  },
  // api resource URLs
  adminResourceUrl: 'https://qa-api.seeknow.jobs/',
  AzureBlobUrl: 'https://seeknowdev.blob.core.windows.net',

  careerPortalBaseUrl: 'https://qatalent.seeknow.jobs/job-openings/',

  // localhost resource URLs
   //adminResourceUrl: 'https://localhost:7046/',

  IdentityServerConfig: {
    AuthorityUrl: 'https://qa-api.seeknow.jobs',
    ClientId: 'seeknowapp',
    ClientSecret: 'K7gNU3sdo+OL0wNhqoVWhr3g6s1xYv72ol/pe/Unols=',
    AppClientId: '81451b81-6741-47d8-6e3d-08da9baa1d04',
    AppClientSecret: '5$CCTlrqMfA!i!543'
  },

  GoogleClientId: '255075571041-s9ga0higgptabcshr51ms3reh7b4pp2u.apps.googleusercontent.com',
  FacebookClientId: 'Your-Facebook-App-Id'
}

