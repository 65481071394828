export const appConstants = {
  emptyGuid: "00000000-0000-0000-0000-000000000000"
}

export const HistoryAction = {
  Type: {
    Moved: "Moved",
    MarkedAsFavourite: "Favourite",
    MarkedAsUnfavourit: "Unfavourite",
    Applied: 'Applied',
    EventScheduled: 'EventScheduled',
    EventCancelled: 'EventCancelled',
    EventRescheduled: 'EventRescheduled',
    CandidateEmailSent: 'CandidateEmailSent',
    InterviewerCommentAdded: 'InterviewerCommentAdded',
    InterviewerCommentDeleted: 'InterviewerCommentDeleted'
  }

}

