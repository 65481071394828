import { Pipe, PipeTransform } from '@angular/core';
import { toZonedTime } from 'date-fns-tz'

@Pipe({
  name: 'withinADay'
})
export class WithinADay implements PipeTransform {

  twentyFourHoursAgo = toZonedTime(new Date().getTime() - 24 * 60 * 60 * 1000, "utc");

  transform(value: string): boolean {
    if (!value) {
      return false;
    }
    
    let targetDate = new Date(value);
    let withinADay = targetDate.getTime() > this.twentyFourHoursAgo.getTime();
    return withinADay;
  }
}
