import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MentionIndices } from './mention-indices.model';

@Pipe({
  name: 'mentionTextFormatter',
})
export class MentionTextFormatterPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }
  transform(commentDescription: string, indices: MentionIndices[] | null): string {
    if (indices === null || !indices.length) {
      return commentDescription;
    }

    return this.getFormattedHighlightText(commentDescription, indices)
  }

  getFormattedHighlightText(
    content: string,
    indices: MentionIndices[]
  ) {
    let highlightedContent = content;
    let replaceContentIndex = 0;
    indices.forEach((range) => {
      const start = range.start;
      const end = range.end;
      const highlightedText = content.substring(start, end);
      const highlighted = `<span style="background-color: ${this.parentCommentStatusBasedStyles.bgColor};color: ${this.parentCommentStatusBasedStyles.color};white-space: nowrap;border: 1px solid ${this.parentCommentStatusBasedStyles.bdColor};padding: 0 3px;border-radius: 3px;">${highlightedText}</span>`;
      const newReplace = highlightedContent
        .substring(replaceContentIndex)
        .replace(highlightedText, highlighted);
      highlightedContent =
        replaceContentIndex === 0
          ? newReplace
          : highlightedContent.substring(0, replaceContentIndex) + newReplace;
      replaceContentIndex = highlightedContent.lastIndexOf('</span>') + 7;
    });

    highlightedContent = highlightedContent.replace(/\n/g, '<br>');
    return this.sanitizer.bypassSecurityTrustHtml(
      highlightedContent
    ) as string;
  }

  parentCommentStatusBasedStyles = {
    bgColor: '#d4ebff',
    color: '#3380c4',
    bdColor: '#87ceeb',
  };
}
