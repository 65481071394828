import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Pipe({
  name: 'adminUserProfileImage'
})
export class AdminUserProfileImagePipe implements PipeTransform {

  adminUserProfileImagePath = `${environment.AzureBlobUrl}/seeknow/AdminUserProfileImages/`;

  transform(profileImageName: string | null, imageSize: string): string {
    let imagePreviewPath: string = "";
    if (!profileImageName) {
      imagePreviewPath = `assets/profile/profile-image-placeholders/${imageSize}-user-placeholder.png`;
    } else {
      imagePreviewPath = `${this.adminUserProfileImagePath}${imageSize}/${profileImageName}`;
    }
    return imagePreviewPath;
  }
}
