import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileExtensionClass',
})
export class FileExtensionClassPipe implements PipeTransform {
  transform(fileName: string | undefined | null): string {
    if (!fileName) return '';

    const extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();

    switch (extension) {
      case 'pdf':
        return 'pdf-file';
      case 'doc':
      case 'docx':
        return 'word-file';
      default:
        return 'unknown-file';
    }
  }
}
