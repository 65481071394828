import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventVenueModel } from 'src/app/settings/settings-pages/settings-event-venue/models/event-venue.model';

@Component({
  selector: 'app-event-venue-form',
  templateUrl: './event-venue-form.component.html',
  styleUrl: './event-venue-form.component.scss'
})

export class EventVenueFormComponent implements OnInit, AfterContentChecked {
  @Input() public eventVenue: EventVenueModel = new EventVenueModel();
  @Output() emitEventVenue: EventEmitter<EventVenueModel> = new EventEmitter();
  componentTitle: string = '';
  isSubmitted: boolean = false;

  constructor(
    private activeModal: NgbActiveModal
  ) {

  }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    if (this.eventVenue.id) {
      this.componentTitle = "Edit";
    } else {
      this.componentTitle = "Create New";
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  saveData(form: any) {
    this.isSubmitted = true;
    if (form.valid) {
      this.emitEventVenue.emit(this.eventVenue);
    }
  }
}
