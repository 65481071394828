import { NgModule } from '@angular/core'
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common'

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbCollapse, NgbDropdownModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { NotFoundComponent } from './feature/not-found/not-found.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxSortableModule } from 'ngx-sortable';
import { SortPipe } from './pipes/sort-pipe';
import { QuillConfigModule, QuillModule } from 'ngx-quill';
import { TagInputModule } from 'ngx-chips'
import { BlockUIModule } from 'ng-block-ui';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { JobDesignationFormComponent } from './settings/job-designation-form/job-designation-form.component';
import { DepartmentFormComponent } from './settings/department-form/department-form.component';
import { EmploymentTypeFormComponent } from './settings/employment-type-form/employment-type-form.component';
import { ExperienceLevelFormComponent } from './settings/experience-level-form/experience-level-form.component';
import { EducationLevelFormComponent } from './settings/education-level-form/education-level-form.component';
import { JobTypeFormComponent } from './settings/job-type-form/job-type-form.component';
import { JobRoleFormComponent } from './settings/job-role-form/job-role-form.component';
import { EventVenueFormComponent } from './settings/event-venue-form/event-venue-form.component';
import { ApplicantProfileImagePipe } from './pipes/applicant-profile-image-pipe';
import { CandidateHistoryPayloadFormatPipe } from './pipes/format-candidate-history-payload-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { ParseDatePipe } from './pipes/parse-date-pipe';
import { WithinADay } from './pipes/within-A-Day-pipe';
import { AdminUserProfileImagePipe } from './pipes/admin-user-profile-image-pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import Quill from 'quill'
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FileExtensionClassPipe } from './pipes/file-extension-class.pipe';
import { DocumentExtensionLogoPipe } from './pipes/document-extension-logo-pipe';
import { MentionTextFormatterPipe } from './feature/mentions/mention-text-formatter.pipe';
import { AuthorizationDirective } from './directives/authorization.directive';
const DirectionAttribute: any = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);
const AlignClass: any = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);
const BackgroundClass: any = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);
const ColorClass: any = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);
const DirectionClass: any = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);
const FontClass: any = Quill.import('attributors/class/font');
Quill.register(FontClass, true);
const SizeClass: any = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);
const AlignStyle: any = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);
const BackgroundStyle: any = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);
const ColorStyle: any = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);
const DirectionStyle: any = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
const FontStyle: any = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);
const SizeStyle: any = Quill.import('attributors/style/size');
Quill.register(SizeStyle, true);

@NgModule({
  providers: [
    DatePipe
  ],
  declarations: [
    SortPipe,
    NotFoundComponent,
    JobDesignationFormComponent,
    DepartmentFormComponent,
    EmploymentTypeFormComponent,
    ExperienceLevelFormComponent,
    EducationLevelFormComponent,
    JobTypeFormComponent,
    JobRoleFormComponent,
    EventVenueFormComponent,
    ApplicantProfileImagePipe,
    CandidateHistoryPayloadFormatPipe,
    ParseDatePipe,
    WithinADay,
    AdminUserProfileImagePipe,
    SafeHtmlPipe,
    FileExtensionClassPipe,
    DocumentExtensionLogoPipe,
    MentionTextFormatterPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxPaginationModule,
    NgbModule,
    FontAwesomeModule,
    NgOptimizedImage,
    TranslateModule,
    NgbCollapse,
    TabsModule,
    ImageCropperModule,
    PdfViewerModule,
    NgbDropdownModule,
    NgSelectModule,
    NgbTooltipModule,
    FlexLayoutModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgxSortableModule,
    SortableModule,
    QuillModule.forRoot(),
    QuillConfigModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          //['blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
          //[{ 'direction': 'rtl' }],                         // text direction
          // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          //[{ 'font': [] }],
          [{ 'align': [] }],
        ]
      }
    }),
    TagInputModule,
    BlockUIModule.forRoot(),
    DragDropModule,
    NgPipesModule,
    NgMultiSelectDropDownModule.forRoot(),
    AngularMultiSelectModule,
    AuthorizationDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxPaginationModule,
    NgbModule,
    FontAwesomeModule,
    SortPipe,
    NgOptimizedImage,
    NotFoundComponent,
    TranslateModule,
    NgbCollapse,
    TabsModule,
    ImageCropperModule,
    PdfViewerModule,
    NgbDropdownModule,
    NgSelectModule,
    NgbTooltipModule,
    FlexLayoutModule,
    CalendarModule,
    NgxSortableModule,
    SortableModule,
    QuillModule,
    TagInputModule,
    BlockUIModule,
    DragDropModule,
    JobDesignationFormComponent,
    DepartmentFormComponent,
    EmploymentTypeFormComponent,
    ExperienceLevelFormComponent,
    EducationLevelFormComponent,
    JobTypeFormComponent,
    JobRoleFormComponent,
    EventVenueFormComponent,
    ApplicantProfileImagePipe,
    CandidateHistoryPayloadFormatPipe,
    NgPipesModule,
    ParseDatePipe,
    WithinADay,
    NgMultiSelectDropDownModule,
    AdminUserProfileImagePipe,
    AngularMultiSelectModule,
    SafeHtmlPipe,
    FileExtensionClassPipe,
    DocumentExtensionLogoPipe,
    MentionTextFormatterPipe,
    AuthorizationDirective
  ]
})
export class SharedModule { }
