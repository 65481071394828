import { Pipe, PipeTransform, computed } from '@angular/core';
import { ApplicantHistory } from 'src/app/jobs/models/applicant-history.model';
import { HistoryAction } from '../app.constants';
import { StateObserverService } from '../services/state-observer.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { UserTimezoneService } from '../services/user-timezone.service';

@Pipe({
  name: 'candidateHistoryFormatter'
})
export class CandidateHistoryPayloadFormatPipe implements PipeTransform {
  historyAction = HistoryAction;
  payload: any = null;

  applicantGeneralInfo = computed(() => this.stateObserverService.applicantGeneralInfo());

  constructor(private stateObserverService: StateObserverService,
    private sanitizer: DomSanitizer,
    private datepipe: DatePipe,
    private userTimezoneService: UserTimezoneService) {
  }

  transform(actionDetail: string, applicantHistory: ApplicantHistory): SafeHtml {

    if (applicantHistory.placeHolderData) {
      this.payload = JSON.parse(applicantHistory.placeHolderData);
    }

    if (applicantHistory.actionType === this.historyAction.Type.Moved) {
      actionDetail = applicantHistory.actionDetail
        .replace('{pipelineStatusName}', `<span class="fw-medium">${this.payload.pipelineStatusName}</span>`)
        .replace('{candidateName}', `<span class="highlight-txt">${this.applicantGeneralInfo()?.firstName} ${this.applicantGeneralInfo()?.lastName}</span>`);
    }

    else if (applicantHistory.actionType === this.historyAction.Type.EventScheduled) {

      let localStartTime = this.userTimezoneService.toUserTimezone(this.payload.eventStartDateTimeUtc);

      actionDetail = applicantHistory.actionDetail
        .replace('{eventTypeName}', `<strong class="fw-medium">${this.payload.eventTypeName}</strong>`)
        .replace('{candidateName}', `<span class="highlight-txt">${this.applicantGeneralInfo()?.firstName} ${this.applicantGeneralInfo()?.lastName}</span>`)
        .replace('{scheduleDateTime}', `<strong class="fw-medium">${this.datepipe.transform(localStartTime, 'dd-MMM-yyyy')} at ${this.datepipe.transform(localStartTime, 'hh:mm a')}</strong>`);
    }

    else if (applicantHistory.actionType === this.historyAction.Type.EventCancelled) {

      let localStartTime = this.userTimezoneService.toUserTimezone(this.payload.eventStartDateTimeUtc);

      actionDetail = applicantHistory.actionDetail
        .replace('{eventTypeName}', `<strong class="fw-medium">${this.payload.eventTypeName.toLowerCase()}</strong>`)
        .replace('{candidateName}', `<span class="highlight-txt">${this.applicantGeneralInfo()?.firstName} ${this.applicantGeneralInfo()?.lastName}</span>`)
        .replace('{scheduleDateTime}', `<strong class="fw-medium">${this.datepipe.transform(localStartTime, 'dd-MMM-yyyy')} at ${this.datepipe.transform(localStartTime, 'hh:mm a')}</strong>`);
    }

    else if (applicantHistory.actionType === this.historyAction.Type.EventRescheduled) {

      let localOldStartTime = this.userTimezoneService.toUserTimezone(this.payload.oldEventStartDateTimeUtc);
      let localNewStartTime = this.userTimezoneService.toUserTimezone(this.payload.newEventStartDateTimeUtc);

      actionDetail = applicantHistory.actionDetail
        .replace('{oldScheduleDateTime}', `<strong class="fw-medium">${this.datepipe.transform(localOldStartTime, 'dd-MMM-yyyy')} at ${this.datepipe.transform(localOldStartTime, 'hh:mm a')}</strong>`)
        .replace('{newScheduleDateTime}', `<strong class="fw-medium">${this.datepipe.transform(localNewStartTime, 'dd-MMM-yyyy')} at ${this.datepipe.transform(localNewStartTime, 'hh:mm a')}</strong>`)
        .replace('{candidateName}', `<span class="highlight-txt">${this.applicantGeneralInfo()?.firstName} ${this.applicantGeneralInfo()?.lastName}</span>`);
    }

    else if (applicantHistory.actionType === this.historyAction.Type.CandidateEmailSent) {

      let localEmailSentOn = this.userTimezoneService.toUserTimezone(this.payload.emailSentOnUtc);

      actionDetail = applicantHistory.actionDetail
        .replace('{emailSentOnUtc}', `<strong class="fw-medium">${this.datepipe.transform(localEmailSentOn, 'dd-MMM-yyyy')} at ${this.datepipe.transform(localEmailSentOn, 'hh:mm a')}</strong>`)
        .replace('{subject}', `<strong class="fw-medium">${this.payload.subject}</strong>`)
        .replace('{candidateName}', `<span class="highlight-txt">${this.applicantGeneralInfo()?.firstName} ${this.applicantGeneralInfo()?.lastName}</span>`)
        .replace('{email}', `<strong class="fw-medium">email</strong>`);
    }

    else if (applicantHistory.actionType === this.historyAction.Type.InterviewerCommentAdded) {
      let localCommentedOn = this.userTimezoneService.toUserTimezone(this.payload.commentedTimeUtc);

      actionDetail = applicantHistory.actionDetail
        .replace('{commentedTimeUtc}', ` <strong class="fw-medium">${this.datepipe.transform(localCommentedOn, 'dd-MMM-yyyy')} at ${this.datepipe.transform(localCommentedOn, 'hh:mm a')}</strong>`)
        .replace('{candidateName}', `<span class="highlight-txt">${this.applicantGeneralInfo()?.firstName} ${this.applicantGeneralInfo()?.lastName}</span>`)
        .replace('{comment}', `<strong class="fw-medium">comment </strong>`);
    }

    else if (applicantHistory.actionType === this.historyAction.Type.InterviewerCommentDeleted) {
      let localCommentedOn = this.userTimezoneService.toUserTimezone(this.payload.commentedTimeUtc);

      actionDetail = applicantHistory.actionDetail
        .replace('{commentDeletedTimeUtc}', ` <strong class="fw-medium">${this.datepipe.transform(localCommentedOn, 'dd-MMM-yyyy')} at ${this.datepipe.transform(localCommentedOn, 'hh:mm a')}</strong>`)
        .replace('{candidateName}', `<span class="highlight-txt">${this.applicantGeneralInfo()?.firstName} ${this.applicantGeneralInfo()?.lastName}</span>`)
        .replace('{comment}', `<strong class="fw-medium">comment </strong>`);
    }

    return this.sanitizer.bypassSecurityTrustHtml(actionDetail);
  }
}
