import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobDesignationModel } from 'src/app/jobs/models/job-settings/job-designation.model';

@Component({
  selector: 'app-job-designation-form',
  templateUrl: './job-designation-form.component.html',
  styleUrl: './job-designation-form.component.scss'
})

export class JobDesignationFormComponent implements OnInit, AfterContentChecked {

  @Input() public jobDesignation: JobDesignationModel = new JobDesignationModel();
  @Output() emitJobDesignation: EventEmitter<any> = new EventEmitter();
  componentTitle: string = '';
  isSubmitted: boolean = false;

  constructor(
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    if (this.jobDesignation.id) {
      this.componentTitle = "Edit";
    } else {
      this.componentTitle = "Create New";
    }
  }

  closeModal() {
    this.activeModal.close();
  }


  saveData(form: any) {
    this.isSubmitted = true;
    if (form.valid) {
      this.emitJobDesignation.emit(this.jobDesignation);      
    }
  }

}
