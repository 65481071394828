import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EducationLevelModel } from 'src/app/jobs/models/job-settings/education-level.model';

@Component({
  selector: 'app-education-level-form', 
  templateUrl: './education-level-form.component.html',
  styleUrl: './education-level-form.component.scss'
})
export class EducationLevelFormComponent implements OnInit, AfterContentChecked {
  
  @Input() public educationLevel: EducationLevelModel = new EducationLevelModel();
  @Output() emitEducationLevel: EventEmitter<any> = new EventEmitter();
  componentTitle: string = '';
  isSubmitted: boolean = false;

  constructor(
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    if (this.educationLevel.id) {
      this.componentTitle = "Edit";
    } else {
      this.componentTitle = "Create New";
    }
  }

  closeModal() {
    this.activeModal.close();
  }


  saveData(form: any) {
    this.isSubmitted = true;
    if (form.valid) {
      this.emitEducationLevel.emit(this.educationLevel);     
    }
  }

}
