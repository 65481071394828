import { Directive, OnInit, ElementRef, Input } from "@angular/core";
import { StateObserverService } from "../services/state-observer.service";


@Directive({
    selector: '[SNAuthorization]',
    standalone: true
})

export class AuthorizationDirective implements OnInit {

    constructor(private el: ElementRef, private stateObserverService: StateObserverService) { }

    @Input('SNAuthorization') rolePermission = Array<string>();
    ngOnInit(): void {
        if (!this.rolePermission.some(s => s.toLowerCase() === this.stateObserverService.loggedInUser()!.roleName!.toLowerCase())) {
            this.el.nativeElement.style.display = 'none';
            this.el.nativeElement.className = 'disappear';
            this.el.nativeElement.remove();
        }
    }

}
