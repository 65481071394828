import { Injectable } from '@angular/core';
import { StateObserverService } from './state-observer.service';
import moment from 'moment';
import { toZonedTime } from 'date-fns-tz';

@Injectable({
  providedIn: 'root'
})
export class UserTimezoneService {

  constructor(private stateObserverService: StateObserverService) { }

  toUserTimezone(dateTime: Date | string): Date {
    const utcDateTimeString = moment.utc(dateTime).format('YYYY-MM-DDTHH:mm:ss[Z]');
    return toZonedTime(utcDateTimeString, this.stateObserverService.loggedInUser()?.timezoneName!);
  }
}
