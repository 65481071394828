import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DepartmentModel } from 'src/app/jobs/models/job-settings/department.model';

@Component({
  selector: 'app-department-form',
  templateUrl: './department-form.component.html',
  styleUrl: './department-form.component.scss'
})
export class DepartmentFormComponent implements OnInit, AfterContentChecked {

  @Input() public jobDepartment: DepartmentModel = new DepartmentModel();
  @Output() emitDepartment: EventEmitter<any> = new EventEmitter();
  componentTitle: string = '';
  isSubmitted: boolean = false;

  constructor(
    private activeModal: NgbActiveModal
  ) {

  }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    if (this.jobDepartment.id) {
      this.componentTitle = "Edit";
    } else {
      this.componentTitle = "Create New";
    }
  }

  closeModal() {
    this.activeModal.close();
  }


  saveData(form: any) {
    this.isSubmitted = true;
    if (form.valid) {
      this.emitDepartment.emit(this.jobDepartment);
    }
  }
}
