import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmploymentTypeModel } from 'src/app/jobs/models/job-settings/employment-type.model';

@Component({
  selector: 'app-employment-type-form',
  templateUrl: './employment-type-form.component.html',
  styleUrl: './employment-type-form.component.scss'
})
export class EmploymentTypeFormComponent implements OnInit, AfterContentChecked {

  @Input() public employmentType: EmploymentTypeModel = new EmploymentTypeModel();
  @Output() emitEmploymentType: EventEmitter<any> = new EventEmitter();
  componentTitle: string = '';
  isSubmitted: boolean = false;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    if (this.employmentType.id) {
      this.componentTitle = "Edit";
    } else {
      this.componentTitle = "Create New";
    }
  }

  closeModal() {
    this.activeModal.close();
  }


  saveData(form: any) {
    this.isSubmitted = true;
    if (form.valid) {
      this.emitEmploymentType.emit(this.employmentType);      
    }
  }


}
