import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobInterviewerRoleModel } from 'src/app/jobs/models/job-interviewer-role.model';

@Component({
  selector: 'app-job-role-form',  
  templateUrl: './job-role-form.component.html',
  styleUrl: './job-role-form.component.scss'
})
export class JobRoleFormComponent implements OnInit, AfterContentChecked {

  @Input() public jobRole: JobInterviewerRoleModel = new JobInterviewerRoleModel();
  @Output() emitJobRole: EventEmitter<any> = new EventEmitter();
  componentTitle: string = '';
  isSubmitted: boolean = false;

  constructor(
    private activeModal: NgbActiveModal
  ) {

  }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    if (this.jobRole.jobRoleId) {
      this.componentTitle = "Edit";
    } else {
      this.componentTitle = "Create New";
    }
  }

  closeModal() {
    this.activeModal.close();
  }


  saveData(form: any) {
    this.isSubmitted = true;
    if (form.valid) {
      this.emitJobRole.emit(this.jobRole);      
    }
  }



}
