<div class="modal-body">
    <div class="d-flex flex-row align-items-center justify-content-between">
        <h4 class="modal-title box-bullet" id="modal-basic-title">{{componentTitle}} Experience Level</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()"><span aria-hidden="true">&times;</span></button>
    </div>
    <form #modalForm='ngForm' (ngSubmit)="saveData(modalForm)" novalidate>
        <div class="form-group mb-2">
            <label class="col-form-label">Experience Level</label>
            <input type="text" class="form-control" placeholder="Enter value here."
                [(ngModel)]="experienceLevel.experienceLevel" required #inputValue="ngModel" name="inputValue">            
            <div *ngIf='isSubmitted' class='error-message' [hidden]='inputValue.valid'>
                <div class='text-danger' style='font-size: 14px;' *ngIf="inputValue.errors?.['required']">
                    Experience Level is Required.
                </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary">Save</button>
    </form>
</div>