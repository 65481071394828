import { EventEmitter, Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FavouriteJobApplication } from 'src/app/jobs/models/favourite-marked-job-application.model';
import { JobApplicantGeneralInfo } from 'src/app/jobs/models/job-applicant-general-info.model';
import { JobApplicantStatus } from 'src/app/jobs/models/job-applicant-status.model';
import { EventData } from '../models/event-models/event.model';
import { LoggedInUserInfo } from 'src/app/models/user-account/logged-in-user-model';

@Injectable({
  providedIn: 'root'
})
export class StateObserverService {

  constructor() { }

  private jobApplicationStatusChange = new BehaviorSubject<JobApplicantStatus | null>(null);
  jobApplicationStatusChange$ = this.jobApplicationStatusChange.asObservable();

  updateJobApplicationStatus(data: JobApplicantStatus) {
    this.jobApplicationStatusChange.next(data);
  }

  public applicantGeneralInfo = signal<JobApplicantGeneralInfo | null>(null);

  updateApplicantGeneralInfo(data: JobApplicantGeneralInfo) {
    this.applicantGeneralInfo.set(data);
  }

  public loggedInUser = signal<LoggedInUserInfo | Partial<LoggedInUserInfo> | null>(null);

  updateLoggedInUser(data: LoggedInUserInfo | Partial<LoggedInUserInfo>) {
    this.loggedInUser.set(data);
  }

  favouriteBadgeChanged = new EventEmitter<FavouriteJobApplication>();

  emitFavouriteBadgeChange(data: FavouriteJobApplication) {
    this.favouriteBadgeChanged.emit(data);
  }

  refreshHistory = new EventEmitter();

  emitRefreshHistory() {
    this.refreshHistory.emit();
  }
}
