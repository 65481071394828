import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition, faFile, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';

@Pipe({
  name: 'documentExtensionLogo'
})
export class DocumentExtensionLogoPipe implements PipeTransform {

  protected readonly faFilePdf = faFilePdf;  
  protected readonly faFileWord = faFileWord;
  protected readonly faFile = faFile;

  transform(documentName: string | null): IconDefinition {

    let fileExtension = documentName?.substring(documentName.lastIndexOf('.') + 1);

    if (fileExtension == 'docx' || fileExtension == 'doc') {
      return faFileWord;
    }else if(fileExtension == 'pdf'){
      return faFilePdf;
    }else{
      return faFile;
    }

  }
}
